<template>
  <div>
    <template>
      <div>
        <!-- MODAL ADD REWARD / PUNISHMENT -->
        <a-modal :title="payload.type === 'add' ? 'Add Student Activity' : 'Edit Student Activity'"
          :okText="payload.type === 'add' ? 'Add' : 'Update'" :visible="modalVisible" :confirm-loading="confirmLoading"
          @ok="handleOkCreate" @cancel="handleCancel">
          <a-form-item label="Activity Name">
            <a-select size="large" style="width: 100%; height: 40px" v-model="payload.id_activity"
              placeholder="Select Activity">
              <a-select-option v-for="item in dataActivities" :key="item.id" :value="item.id">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="Predicate">
            <a-select size="large" style="width: 40%; height: 40px" placeholder="Select Predicate"
              @change="(e) => handleChange(e)">
              <a-select-option v-for="(data, idx) in dataTemplateActivities " :value="data.id" :key="idx">{{
                data.option
              }}</a-select-option>
              <!-- <a-select-option value="A">A</a-select-option>
              <a-select-option value="B">B</a-select-option>
              <a-select-option value="C">C</a-select-option>
              <a-select-option value="D">D</a-select-option> -->
            </a-select>
          </a-form-item>
          <a-form-item label="Description">
            <a-textarea :auto-size="{ minRows: 5, maxRows: 7 }" class=" mt-3" v-model="payload.description"
              :disabled="true" />
          </a-form-item>
        </a-modal>
        <!-- MODAL ADD REWARD / PUNISHMENT -->
        <!-- MODAL EDITING REWARD & PUNISHMENT -->
        <!-- MODAL EDITING REWARD & PUNISHMENT -->
      </div>
    </template>
    <!-- VIEW -->
    <div v-if="dataGeneralReport.kelas_tahun_ajaran" class="align-items-center">
      <div class="d-flex flex-column flex-md-row">
        <div class="w-100 w-lg-75">
          <a-divider orientation="left">
            <h3>{{ dataGeneralReport.kelas_tahun_ajaran.murid.nama }}</h3>
          </a-divider>
          <p>In this page you can add, edit, delete this student Activities</p>
        </div>
        <div class="d-flex pt-3">
          <template v-if="!editReportRP">
            <!-- <a-button shape="round" type="primary" icon="plus" class="mb-3 mr-2" @click.prevent="showModal">ADD</a-button> -->
            <a-button @click.prevent="showModal('add')" size="large" type="primary"><a-icon type="plus" />ADD
              ACTIVITIES</a-button>
            <!-- <a-button shape="round" :type="!dataTable.length ? 'default' : 'primary'" icon="edit" class="mb-3"
              :disabled="!dataTable.length" @click.prevent="toEditReportRP">EDIT</a-button> -->
          </template>
          <template v-else>
            <a-button @click.prevent="cancelEdit" :loading="loadingCancel" type="danger" shape="round" icon="close"
              class="mb-3 mb-lg-0 mr-0 mr-md-3">CANCEL</a-button>
            <a-button @click.prevent="saveEdit" shape="round" icon="save"
              style="background-color: #41b883; color: white">SAVE</a-button>
          </template>
        </div>
      </div>
      <!-- SHOW TABLE REWARD & PUNISHMENT FOR THIS STUDENT -->
      <div class="pt-3">
        <a-table bordered size="large" :pagination="{ pageSize: 10, hideOnSinglePage: true }" :loading="loadingTable"
          :columns="columnsTableRP" :data-source="dataTable">
          <!-- <div v-if="editReportRP" slot="frequency" slot-scope="text, record">
            <a-input-number size="large" :value="text" @change="(e) => handleEditChange(e, record.key, 'frequency')
              " />
          </div>
          <div v-else slot="frequency" slot-scope="text">
            <span>{{ text }}</span>
          </div>
          <div v-if="editReportRP" slot="note" slot-scope="text, record">
            <a-input :value="text" size="large" @change="(e) => handleEditChange(e.target.value, record.key, 'note')
              " />
          </div>
          <div v-else slot="note" slot-scope="text">
            <span>{{ text }}</span>
          </div> -->
          <div slot="action" slot-scope="value, record" class="justify-content-center d-flex my-2">
            <a-button @click.prevent="showModal('edit', record)" class="w-50 text-warning border border-warning mr-2"
              size="large">
              <a-icon type="edit" />Edit
            </a-button>
            <a-button @click.prevent="deleteActivities(record.key)" class=" text-danger border border-danger"
              size="large">
              <a-icon type="delete" />Delete
            </a-button>
            <!-- <a-button :disabled="editReportRP" size="default" :loading="loadingDelete" :value="text"
              @click.prevent="(e) => deleteRewardPunishment(e.target.value, record.key)" shape="round" type="danger"
              icon="delete">DELETE</a-button> -->
          </div>
        </a-table>
      </div>
      <!-- SHOW TABLE REWARD & PUNISHMENT FOR THIS STUDENT -->
    </div>
    <!-- VIEW -->
  </div>
</template>
<script>
const columnsTableRP = [
  {
    title: 'No.',
    dataIndex: 'no',
    key: 'no',
    scopedSlots: { customRender: 'no' },
    align: 'center',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'name' },
    align: 'center',
  },
  {
    title: 'Predicate',
    dataIndex: 'predicate',
    key: 'predicate',
    scopedSlots: { customRender: 'type' },
    align: 'center',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    scopedSlots: { customRender: 'description' },
    align: 'center',
  },
  // {
  //   title: 'Score',
  //   dataIndex: 'score',
  //   key: 'score',
  //   scopedSlots: { customRender: 'score' },
  //   align: 'center',
  // },
  // {
  //   title: 'Frequency',
  //   dataIndex: 'frequency',
  //   key: 'frequency',
  //   scopedSlots: { customRender: 'frequency' },
  //   align: 'center',
  // },
  // {
  //   title: 'Total Score',
  //   dataIndex: 'totalScore',
  //   key: 'totalScore',
  //   scopedSlots: { customRender: 'totalScore' },
  //   align: 'center',
  // },
  // {
  //   title: 'Note',
  //   dataIndex: 'note',
  //   key: 'note',
  //   scopedSlots: { customRender: 'note' },
  //   align: 'center',
  // },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
]

export default {
  name: 'report-rp',
  data() {
    return {
      payload: {
        id_activity: null,
        predicate: null,
        description: null,
        type: null,
      },
      modalTitle: '',
      loadingCancel: false,
      loadingDelete: false,
      editReportRP: false,
      modalVisible: false,
      modalEditVisible: false,
      confirmLoading: false,
      loadingTable: false,
      columnsTableRP,
      dataTable: [],
      savedData: [],
      dataMasterRP: [],
      dataActivities: [],
      types: [
        {
          key: 1,
          value: 'Reward',
        },
        {
          key: 2,
          value: 'Punishment',
        },
      ],
      selectType: null,
      filterType: [],
      selectDescription: null,
      selectedScore: null,
      frequency: null,
      note: null,
      idGeneralReport: this.$route.params.id,
      dataGeneralReport: {},
      dataTemplateActivities: [],
    }
  },
  methods: {
    async fetchStudentRP() {
      this.loadingTable = true
      try {
        // this.dataGeneralReport = await this.$store.dispatch('homeroom/FETCH_REWARD_PUNISHMENT_BY_GENERAL_REPORT', { idGeneralReport: this.idGeneralReport })
        this.dataGeneralReport = await this.$store.dispatch('homeroom/FETCH_ACTIVITIES_BY_GENERAL_REPORT', { idGeneralReport: this.idGeneralReport })
        this.dataTemplateActivities = await this.$store.dispatch('homeroom/FETCH_DATA_PREDICATE_ACTIVITIES')
        // console.log(this.dataTemplateActivities)
        // console.log(this.dataGeneralReport)
        const mapTable = this.dataGeneralReport.report_activities.map(
          (row, idx) => {
            // const type = row.reward_punishment.type
            // const typeCapitalized = type.charAt(0).toUpperCase() + type.slice(1)
            return {
              key: row.id,
              no: idx + 1,
              // id_general_report: row.id_general_report,
              // id_reward_punishment: row.id_reward_punishment,
              id_activity: row.id_activities,
              name: row?.activity.name,
              description: row.description,
              predicate: row.predicate,
              // type: typeCapitalized,
              // description: row.reward_punishment.description,
              // score: row.reward_punishment.score,
              // frequency: row.frequency,
              // totalScore: row.reward_punishment.score * row.frequency,
              // note: row.note || '-',
            }
          },
        )
        this.dataTable = mapTable
        this.savedData = this.dataTable
        this.loadingTable = false
        return new Promise((resolve, reject) => resolve())
      } catch (err) {
        this.loadingTable = false
        return new Promise((resolve, reject) => reject(err))
      }
    },
    showModal(type, record) {
      // console.log(record)
      if (type === 'add') {
        this.payload.type = 'add'
      } else {
        this.payload.type = 'edit'
        this.payload.id = record.id
        this.payload.id_activity = record.id_activity
        this.payload.description = record.description
        this.payload.predicate = record.predicate
        this.payload.id_report_activities = record.key
      }
      this.modalVisible = true
    },
    handleChange(value) {
      const filteredTemplate = this.dataTemplateActivities.filter((e) => e.id === value)
      this.payload.predicate = filteredTemplate[0].option
      this.payload.description = filteredTemplate[0].description
    },
    handleOkCreate() {
      const data = {
        ...this.payload,
        isActivities: true,
      }
      this.$emit('child-rpp', data)
      this.editReportRP = false
      this.modalVisible = false
      this.confirmLoading = false
    },
    handleCancel() {
      this.modalVisible = false
      this.payload = {
        id_activity: null,
        predicate: null,
        description: null,
        type: null,
      }
      this.modalEditVisible = false
    },
    fetchMasterRP(
      params = {
        page: 'all',
        order: 'ASC',
        sortBy: '',
        search: '',
        type: '',
      },
    ) {
      this.loading = true
      this.$store
        .dispatch('admin/FETCH_ALL_REWARD_PUNISHMENT', {
          page: params.page,
          order: params.order,
          sortBy: params.sortBy,
          search: params.search,
          type: params.type,
        })
        .then((data) => {
          // console.log('data fetchDataRewardPunishment', data)
          this.dataMasterRP = data.rewardPunishments.map(row => {
            return {
              id: row.id,
              type: row.type,
              description: row.description,
              score: row.score,
            }
          })
        })
      this.$store
        .dispatch('admin/FETCH_ALL_ACTIVITIES', {
          page: params.page,
          order: params.order,
          sortBy: params.sortBy,
          search: params.search,
          type: params.type,
        })
        .then((data) => {
          // console.log('data activitieas all', data)
          this.dataActivities = data.activities
          // this.dataMasterRP = data.rewardPunishments.map(row => {
          //   return {
          //     id: row.id,
          //     type: row.type,
          //     description: row.description,
          //     score: row.score,
          //   }
          // })
        })
    },
    handleSelectType(value) {
      this.filterType = []
      this.selectedScore = null
      // console.log('value', value)
      this.selectType = value.toLowerCase()
      // console.log('this.selectType', this.selectType)
      const target = this.dataMasterRP.filter(el => el.type === this.selectType)
      // console.log('target', target)
      if (target) {
        this.filterType = target
        if (this.selectedScore === null && this.selectDescription !== null) {
          this.$notification.warning({
            message: 'DANGER!',
            description:
              `Please re-select the ${this.selectType} description`,
          })
        }
      }
    },
    handleSelectDescription(value) {
      // console.log('value', value)
      this.selectDescription = value
      // console.log('this.dataMasterRP', this.dataMasterRP)
      const target = this.dataMasterRP.filter(el => el.id === this.selectDescription)
      // console.log('target', target)
      if (target) {
        this.selectedScore = target[0].score
      }
    },
    toEditReportRP() {
      this.editReportRP = true
    },
    saveEdit() {
      this.modalEditVisible = true
    },
    cancelEdit() {
      this.loadingCancel = true
      setTimeout(() => {
        this.editReportRP = false
        this.loadingCancel = false
      }, 2000)
    },
    handleEditChange(value, key, column) {
      const newData = [...this.dataTable]
      const target = newData.filter(item => key === item.key)[0]
      if (target) {
        target[column] = value
        this.savedData = newData
      }
    },
    validateDataInput(data) {
      let isInvalid = false
      let message = ''
      for (let i = 0; i < data.length; i++) {
        // console.log(data[i])
        if (data[i].frequency === '-') {
          data[i].frequency = null
        }
        if (isNaN(Number(data[i].frequency))) {
          isInvalid = true
          message = `Sorry, ${data[i].description}'s frequency must be numbers. ${i}`
          break
        }
      }
      return {
        isInvalid,
        message,
      }
    },
    handleOkUpdate() {
      // console.log(this.savedData)
      const newData = [...this.savedData]
      if (newData.length) {
        const validation = this.validateDataInput(newData)
        if (validation.isInvalid) {
          this.$notification.error({
            message: 'Sorry.',
            description: validation.message,
          })
          this.modalVisible = false
        } else {
          const target = newData.map((row) => {
            return {
              id: row.key,
              id_reward_punishment: row.id_reward_punishment,
              id_general_report: row.id_general_report,
              frequency: Number(row.frequency),
              note: row.note,
            }
          })
          // console.log('Berhasil', target)
          const data = {
            type: 'edit',
            dataEdit: target,
          }
          this.fetchStudentRP()
          this.$emit('child-rpp', data)
          this.editReportRP = false
          this.modalEditVisible = false
          this.confirmLoading = false
        }
      } else {
        this.editReportRP = false
        this.confirmLoading = true
        setTimeout(() => {
          this.$notification.error({
            message: 'Sorry',
            description: 'Reward / Punishment has not been updated',
          })
          this.modalEditVisible = false
          this.confirmLoading = false
        }, 1000)
      }
    },
    deleteActivities(key) {
      this.loadingDelete = true
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure you want to delete this {this.dataGeneralReport.kelas_tahun_ajaran.murid.nama} Activities ? This student data will be lost</div>
        ),
        onOk: () => {
          const data = {
            type: 'delete',
            id: key,
            isActivities: true,
          }
          this.$emit('child-rpp', data)
          this.loadingDelete = false
        },
        onCancel: () => {
          this.loadingDelete = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Remove',
      })
    },
  },
  created() {
    this.fetchStudentRP()
    this.fetchMasterRP()
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
  },
}
</script>
<style lang="scss">
.testBorder {
  border: 2px solid black;
}
</style>
